import { createSlice } from "@reduxjs/toolkit"

export const themeSlice = createSlice({
    name: "theme",
    initialState: {
        themeList: [],
        themeDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setThemeList: (state, action) => {
            state.themeList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.themeDetail = action.payload
        }
    }
})

export const {
    setThemeList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = themeSlice.actions

export const selectthemeList = (state) => state.theme.themeList
export const selectFilterTerm = (state) => state.theme.filterTerm
export const selectTotalEntry = (state) => state.theme.totalEntry
export const selectthemeDetail = (state) => state.theme.themeDetail

export default themeSlice.reducer
