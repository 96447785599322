import { createSlice } from "@reduxjs/toolkit"

export const readinessSlice = createSlice({
    name: "readiness",
    initialState: {
        readinessList: [],
        readinessDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setReadinessList: (state, action) => {
            state.readinessList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.readinessDetail = action.payload
        }
    }
})

export const {
    setReadinessList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = readinessSlice.actions

export const selectreadinessList = (state) => state.readiness.readinessList
export const selectFilterTerm = (state) => state.readiness.filterTerm
export const selectTotalEntry = (state) => state.readiness.totalEntry
export const selectreadinessDetail = (state) => state.readiness.readinessDetail

export default readinessSlice.reducer
