import { createSlice } from "@reduxjs/toolkit"

export const dimensionSlice = createSlice({
    name: "dimension",
    initialState: {
        dimensionList: [],
        dimensionDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setDimensionList: (state, action) => {
            state.dimensionList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.dimensionDetail = action.payload
        }
    }
})

export const {
    setDimensionList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = dimensionSlice.actions

export const selectdimensionList = (state) => state.dimension.dimensionList
export const selectFilterTerm = (state) => state.dimension.filterTerm
export const selectTotalEntry = (state) => state.dimension.totalEntry
export const selectdimensionDetail = (state) => state.dimension.dimensionDetail

export default dimensionSlice.reducer
