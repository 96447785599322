import { createSlice } from "@reduxjs/toolkit"

export const showDocumentSlice = createSlice({
  name: "showDocument",
  initialState: {
    showDocumentList: [], 
    exportList: [],
    showDocumentDetail: {},
    totalEntry: 0,
    filterTerm: ''
  },
  reducers: {
    setShowDocumentList: (state, action) => {
      state.showDocumentList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.showDocumentDetail = action.payload
    }

  }
})

export const {
  setShowDocumentList,
  setExportList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = showDocumentSlice.actions

export const selectShowDocumentList = (state) => state.showDocument.showDocumentList
export const selectExportList = (state) => state.showDocument.exportList
export const selectFilterTerm = (state) => state.showDocument.filterTerm
export const selectTotalEntry = (state) => state.showDocument.totalEntry
export const selectShowDocumentDetail = (state) => state.showDocument.showDocumentDetail

export default showDocumentSlice.reducer
