import { createSlice } from "@reduxjs/toolkit"

export const indicatorSlice = createSlice({
    name: "indicator",
    initialState: {
        indicatorList: [],
        indicatorDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setIndicatorList: (state, action) => {
            state.indicatorList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.indicatorDetail = action.payload
        }
    }
})

export const {
    setIndicatorList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = indicatorSlice.actions

export const selectindicatorList = (state) => state.indicator.indicatorList
export const selectFilterTerm = (state) => state.indicator.filterTerm
export const selectTotalEntry = (state) => state.indicator.totalEntry
export const selectindicatorDetail = (state) => state.indicator.indicatorDetail

export default indicatorSlice.reducer
