import { createSlice } from "@reduxjs/toolkit"

export const sectionSlice = createSlice({
    name: "section",
    initialState: {
        sectionList: [],
        sectionDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setSectionList: (state, action) => {
            state.sectionList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.sectionDetail = action.payload
        }
    }
})

export const {
    setSectionList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = sectionSlice.actions

export const selectSectionList = (state) => state.section.sectionList
export const selectFilterTerm = (state) => state.section.filterTerm
export const selectTotalEntry = (state) => state.section.totalEntry
export const selectSectionDetail = (state) => state.section.sectionDetail

export default sectionSlice.reducer
