import { createSlice } from "@reduxjs/toolkit"

export const questionSlice = createSlice({
    name: "question",
    initialState: {
        questionList: [],
        questionDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setQuestionList: (state, action) => {
            state.questionList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.questionDetail = action.payload
        }
    }
})

export const {
    setQuestionList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = questionSlice.actions

export const selectquestionList = (state) => state.question.questionList
export const selectFilterTerm = (state) => state.question.filterTerm
export const selectTotalEntry = (state) => state.question.totalEntry
export const selectquestionDetail = (state) => state.question.questionDetail

export default questionSlice.reducer
