import { createSlice } from "@reduxjs/toolkit"

export const industrySlice = createSlice({
    name: "industry",
    initialState: {
        industryList: [],
        industryDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setIndustryList: (state, action) => {
            state.industryList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.industryDetail = action.payload
        }
    }
})

export const {
    setIndustryList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = industrySlice.actions

export const selectIndustryList = (state) => state.industry.industryList
export const selectFilterTerm = (state) => state.industry.filterTerm
export const selectTotalEntry = (state) => state.industry.totalEntry
export const selectIndustryDetail = (state) => state.industry.industryDetail

export default industrySlice.reducer
